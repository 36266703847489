import { ExclamationIcon } from "@heroicons/react/outline";

const Error = ({ text }) => {
	return (
		<>
			<span className="flex flex-col items-center">
				<ExclamationIcon className="h-8 w-8 text-red-700" />
				{text && <p className="!mt-4">{text}</p>}
			</span>
		</>
	);
};

export default Error;
