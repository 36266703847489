import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const Footer = () => {
	return (
		<>
			<div className="max-w-container mx-auto w-full mt-8 px-4 sm:px-6 lg:px-8">
				<div className="border-t border-slate-900/5 py-10">
					<img
						className="mx-auto h-8 w-auto sm:h-10"
						src="https://lucdn.letsupgrade.net/small_logo_new_0cd08c57ce.png"
						alt="LetsUpgrade"
					/>
					<p className="mt-5 text-center text-sm leading-6 text-slate-500">
						LetsUpgrade EdTech Pvt. Ltd. ©{" "}
						{new Date().getFullYear()}
					</p>
					<p className="text-center text-sm leading-6 text-slate-400">
						v{publicRuntimeConfig?.version}
					</p>
					<div className="mt-8 flex items-center justify-center space-x-4 text-sm font-semibold leading-6 text-slate-700">
						<a
							href="https://letsupgrade.in/privacy"
							target="_blank"
						>
							Privacy Policy
						</a>
						<div className="h-4 w-px bg-slate-500/20"></div>
						<a href="https://letsupgrade.in/terms" target="_blank">
							Terms & Conditions
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
