import { Loader } from "components";
import React from "react";

const Image = ({ isLoadingImg, certificateImg, errorImg }) => {
	return (
		<>
			{!errorImg ? (
				<div className="flex h-full w-full flex-col space-y-2 md:w-3/4">
					<div className="overflow-hidden rounded-md border-[1px] border-slate-300">
						{!isLoadingImg && certificateImg ? (
							<img
								src={certificateImg.url}
								alt=""
								onContextMenu={(e) => e.preventDefault()}
							/>
						) : (
							<div className="flex h-96 items-center justify-center">
								<Loader text="Spinning the wheel of fortune..." />
							</div>
						)}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default Image;
