import { ArrowCircleDownIcon } from "@heroicons/react/outline";
import { ShareIcon } from "@heroicons/react/solid";
import { ShareModal } from "components";
import { useRouter } from "next/router";
import { useState } from "react";
import classNames from "utils/classNames";
import formatDate from "utils/formatDate";

const Text = ({ certificateImg, certificate, isNew }) => {
	const [showShare, setShowShare] = useState(false);
	const { query } = useRouter();
	const { certificateId } = query;

	const download = () => {
		const link = document.createElement("a");
		link.href = certificateImg;
		link.setAttribute("download", `${certificateId}.png`);
		document.body.appendChild(link);
		link.click();
		setTimeout(() => document.body.removeChild(link), 5000);
	};

	const closeShare = () => setShowShare(false);

	return (
		<>
			<div
				className={classNames(
					"flex w-full flex-col space-y-5",
					isNew ? "md:w-1/4" : ""
				)}
			>
				{isNew && (
					<div className="flex flex-col space-y-4">
						<div className="flex space-x-3">
							<img
								src={
									certificate.user?.profileImageUrl
										? certificate.user?.profileImageUrl
										: `https://avatars.dicebear.com/api/initials/${encodeURIComponent(
												certificate.user?.name
										  )}.svg`
								}
								className="h-12 w-12 rounded-md"
							/>
							<div className="flex flex-col justify-center">
								<div className="text-lg font-medium text-gray-900">
									{certificate.user?.name}
								</div>
								<a
									href={`https://letsupgrade.in/user/${certificate.user?.username}`}
									target="_blank"
									className="text-sm text-gray-500 hover:text-lu"
								>
									@{certificate.user?.username}
								</a>
							</div>
						</div>
					</div>
				)}
				<div className="flex flex-col items-center justify-center gap-y-5">
					<div className={classNames(isNew ? "w-full" : "w-1/2")}>
						{isNew ? (
							<div className="space-y-2 text-gray-900">
								<div>
									<a
										href={`https://letsupgrade.in/user/${certificate.user?.username}`}
										target="_blank"
										className="text-lu"
									>
										{certificate.user?.name}
									</a>{" "}
									has completed the{" "}
									<span className="text-lu">
										{certificate.program?.title}
									</span>{" "}
									course on{" "}
									<span>
										{
											formatDate(certificate.issuedAt)
												.tertiary
										}
									</span>
									.
								</div>
								<div>
									It indicates that the student has completed
									the entire course.
								</div>
								<div>
									In the entire program, the course duration
									represents the total number of hours of
									learning a student completed.
								</div>
							</div>
						) : (
							<div className="space-y-6 flex flex-col text-center text-gray-900">
								<div className="text-lg font-semibold">
									{certificateId}
								</div>
								<span>
									<span className="text-lu">
										{certificate[0].name}
									</span>
									{` ${certificate[0].description}`}
								</span>
							</div>
						)}
					</div>
					<div
						className={classNames(
							"flex items-center justify-center space-x-2",
							isNew ? "w-full" : "order-last w-1/2"
						)}
					>
						{isNew && (
							<button
								className="flex w-1/2 cursor-pointer items-center justify-center space-x-1 rounded-md border border-gray-900 bg-gray-900 px-4 py-2 tracking-wide text-gray-100 transition ease-in hover:scale-[1.01] hover:shadow-lg md:px-0"
								onClick={download}
							>
								<span>Download</span>
								<ArrowCircleDownIcon className="h-5 w-5" />
							</button>
						)}
						<button
							className="flex w-1/2 cursor-pointer items-center justify-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 tracking-wide text-gray-900 transition ease-in hover:scale-[1.01] hover:border-gray-900 hover:bg-gray-900 hover:text-gray-100 hover:shadow-lg md:px-0"
							onClick={() => setShowShare(true)}
						>
							<span>Share</span>
							<ShareIcon className="h-5 w-5" />
						</button>
					</div>
				</div>
			</div>
			<ShareModal
				showShare={showShare}
				closeShare={closeShare}
				programTitle={certificate.program?.title}
				isNew={isNew}
			/>
		</>
	);
};

export default Text;
