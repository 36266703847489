import { Dialog, Transition } from "@headlessui/react";
import { GlobeAltIcon, ShareIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from "react-share";
import { toast } from "react-toastify";

const ShareModal = ({ showShare, closeShare, programTitle, isNew }) => {
	const shareUrl = window.location.href;
	const quote = `Hey Everyone, I am excited to share that I have completed${
		isNew ? " the " + programTitle : " a course"
	} from Letsupgrade.in. Sharing with you guys the certificate and verification link.`;

	const copy = () => {
		navigator.clipboard
			.writeText(
				`Hey Everyone, I am excited to share that I have completed${
					isNew ? " the " + programTitle : " a course"
				} from Letsupgrade.in. Sharing with you guys the certificate and verification link. 
			${window.location.href}`
			)
			.then(() => toast.success("Copied To Clipboard"));
	};
	return (
		<>
			<Transition appear show={showShare} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeShare}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-50" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<div
										className="flex justify-evenly"
										onClick={closeShare}
									>
										<span
											className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-200"
											onClick={copy}
										>
											<ShareIcon className="h-6 w-6" />
										</span>
										<FacebookShareButton
											url={shareUrl}
											quote={quote}
										>
											<FacebookIcon size={40} round />
										</FacebookShareButton>
										<LinkedinShareButton url={shareUrl}>
											<LinkedinIcon size={40} round />
										</LinkedinShareButton>
										<TwitterShareButton
											url={shareUrl}
											quote={quote}
										>
											<TwitterIcon size={40} round />
										</TwitterShareButton>
										<WhatsappShareButton
											url={shareUrl}
											quote={quote}
										>
											<WhatsappIcon size={40} round />
										</WhatsappShareButton>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default ShareModal;
