import { Footer, Navbar } from "components";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { Router, useRouter } from "next/router";
import nprogress from "nprogress";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "styles/globals.css";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

nprogress.configure({
	minimum: 0.3,
	easing: "ease",
	speed: 800,
	showSpinner: true,
});

Router.events.on("routeChangeStart", () => nprogress.start());
Router.events.on("routeChangeComplete", () => nprogress.done());
Router.events.on("routeChangeError", () => nprogress.done());

const App = ({ Component, pageProps }) => {
	const { query } = useRouter();
	useEffect(() => {
		const handleRouteChange = (url) => {
			if (window !== undefined) {
				window.gtag("config", process.env.GOOGLE_ANALYTICS_ID, {
					page_path: url,
				});
			}
		};

		Router.events.on("routeChangeComplete", handleRouteChange);
		return () => {
			Router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [Router.events]);

	return (
		<>
			<NextSeo
				title="LetsUpgrade"
				titleTemplate="LetsUpgrade"
				defaultTitle="LetsUpgrade"
				description="Enroll in Letsupgrade's online courses to gain certification in data science, digital marketing, product management, machine learning, software development, and more. Get the unique LetsUpgrade Online gamified  learning experience - learn through a content co-developed by academia and industry experts & get a dedicated mentor and career support."
				canonical="https://letsupgrade.in/"
				openGraph={{
					url: "https://letsupgrade.in/",
					title: "LetsUpgrade",
					type: "website",
					description:
						"Enroll in Letsupgrade's online courses to gain certification in data science, digital marketing, product management, machine learning, software development, and more. Get the unique LetsUpgrade Online gamified  learning experience - learn through a content co-developed by academia and industry experts & get a dedicated mentor and career support.",
					site_name: "LetsUpgrade",
					images: [
						{
							url: "https://lucdn.letsupgrade.net/small_logo_new_0cd08c57ce.png",
							width: 800,
							height: 420,
							alt: "LetsUpgrade",
						},
					],
				}}
				// twitter={{
				// 	handle: "@avneesh0612",
				// 	site: "@avneesh0612",
				// 	cardType: "summary_large_image",
				// }}
			/>
			<Head>
				<title>Verify your LetsUpgrade Certificate</title>
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<header>
				<Navbar />
			</header>
			<main>
				<Component {...pageProps} />
				<a
					href={`https://api.whatsapp.com/send?phone=+918591027527&text=Hey, I have an issue regarding a certificate${
						query.certificateId
							? ` with certificate number ${query.certificateId}.`
							: "."
					}`}
					className="fixed bottom-8 right-8 flex h-14 w-14 items-center justify-center rounded-full bg-[#25d366] text-white shadow"
					target="_blank"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
						className="h-8 w-8"
					>
						<path
							fill="currentColor"
							d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
						/>
					</svg>
				</a>
			</main>
			<footer>
				<Footer />
			</footer>
			<ToastContainer
				position="top-right"
				autoClose={2500}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				theme="colored"
				pauseOnHover={false}
				limit={3}
			/>
		</>
	);
};

const AppWithQuery = (props) => {
	return (
		<QueryClientProvider client={queryClient}>
			<App {...props} />
			{process.env.NODE_ENV === "development" && (
				<ReactQueryDevtools initialIsOpen={true} />
			)}
		</QueryClientProvider>
	);
};

export default AppWithQuery;
